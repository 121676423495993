<template>
    <div class="flex flex-col space-y-8 py-24 text-blue-500">
        <div class="flex flex-col md:flex-row items-start justify-between space-y-3 md:space-y-0 md:space-x-3">
            <div class="flex flex-col space-y-5">
                <!-- Reference Period -->
                <div class="flex items-center space-x-2">
                    <span class="capitalize text-2xl">
                        {{ setReferencePeriod }}
                    </span>
                    <button 
                        @click.prevent="() => {selection_date_modal = true;}"
                        class=" bg-transparent hover:bg-gray-200 dark:hover:bg-gray-900 duration-300 rounded p-1">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"/></svg>
                    </button>
                </div>
                <div>
                    {{ $t('product.products', $store.state.locale) }}:
                    <span
                        v-if="status == 'fetched' && 'total' in products" 
                        class="font-semibold">
                        {{ products.total ?? 0 }}
                    </span>
                </div>
            </div>
            <!-- Search -->
            <form method="GET" class="flex items-center justify-center" @submit.prevent="init">
                <input 
                    v-model="search_input" type="text" name="search_input" id="search_input" 
                    class="pl-3 h-10 rounded-l rounded-r-none border hover:border-blue-500 focus:border-blue-500 duration-300 dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400"
                    :placeholder="$t('product.search', $store.state.locale)+'...'">
                <button 
                    class="h-10 px-3 rounded-r border border-blue-500 bg-blue-500 hover:bg-blue-300 text-white flex items-center justify-center duration-300"
                    type="submit">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                </button>
            </form>
        </div>
        <div class="overflow-x-auto overflow-y-hidden z-10 scrollbar-hidden">
            <div class=" p-2 flex items-center justify-between space-x-3">
                <div class="flex items-center space-x-3">
                    <span>{{ $t('tablePagination.per_page', $store.state.locale) }}:  </span>
                    <div class="relative text-left">
                        <button 
                            @click.prevent="showPerPageDropdown = !showPerPageDropdown" 
                            v-click-away="closePerPageDropdown"
                            class="min-w-min flex items-center justify-center hover:text-blue-500 border rounded-md p-2 bg-white dark:bg-gray-800 dark:border-gray-700">
                            <span class="duration-300 text-sm px-2">
                                {{ perPage }}
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-gray-500 dark:text-gray-400"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                        </button>
                        <div v-if="showPerPageDropdown" class="origin-top-right absolute right-0 mt-2 min-w-max max-h-32 overflow-y-auto overscroll-contain rounded shadow-lg bg-white dark:bg-gray-800 border focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <div class="flex flex-col " role="none">
                                <button
                                    class="py-2 px-7 duration-300 dark:border-gray-700  rounded-t"
                                    :class="[perPage === 15 ? 'bg-light-green-500  hover:bg-light-green-700 dark:hover:bg-blue-700 text-white' : 'dark:bg-gray-900  hover:bg-gray-200 dark:hover:bg-gray-700']"
                                    @click.prevent="setPerPage(15)">
                                    15
                                </button>
                                <button
                                    class="py-2 px-7 duration-300 dark:border-gray-700"
                                    :class="[perPage === 30 ? 'bg-light-green-500  hover:bg-light-green-700 dark:hover:bg-blue-700 text-white' : 'dark:bg-gray-900  hover:bg-gray-200 dark:hover:bg-gray-700']"
                                    @click.prevent="setPerPage(30)">
                                    30
                                </button>
                                <button
                                    class="py-2 px-7 duration-300 dark:border-gray-700"
                                    :class="[perPage === 50 ? 'bg-light-green-500  hover:bg-light-green-700 dark:hover:bg-blue-700 text-white' : 'dark:bg-gray-900  hover:bg-gray-200 dark:hover:bg-gray-700']"
                                    @click.prevent="setPerPage(50)">
                                    50
                                </button>
                                <button
                                    class="py-2 px-7 duration-300 dark:border-gray-700"
                                    :class="[perPage === 100 ? 'bg-light-green-500  hover:bg-light-green-700 dark:hover:bg-blue-700 text-white' : 'dark:bg-gray-900  hover:bg-gray-200 dark:hover:bg-gray-700']"
                                    @click.prevent="setPerPage(100)">
                                    100
                                </button>
                                <button
                                    class="py-2 px-7 duration-300 dark:border-gray-700"
                                    :class="[perPage === 150 ? 'bg-light-green-500  hover:bg-light-green-700 dark:hover:bg-blue-700 text-white' : 'dark:bg-gray-900  hover:bg-gray-200 dark:hover:bg-gray-700']"
                                    @click.prevent="setPerPage(150)">
                                    150
                                </button>
                                <button
                                    class="py-2 px-7 duration-300 dark:border-gray-700  rounded-b"
                                    :class="[perPage === 200 ? 'bg-light-green-500  hover:bg-light-green-700 dark:hover:bg-blue-700 text-white' : 'dark:bg-gray-900  hover:bg-gray-200 dark:hover:bg-gray-700']"
                                    @click.prevent="setPerPage(200)">
                                    200
                                </button>
                                <button
                                    class="py-2 px-7 duration-300 dark:border-gray-700  rounded-b"
                                    :class="[perPage === 'all' ? 'bg-light-green-500  hover:bg-light-green-700 dark:hover:bg-blue-700 text-white' : 'dark:bg-gray-900  hover:bg-gray-200 dark:hover:bg-gray-700']"
                                    @click.prevent="setPerPage('all')">
                                    All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-end space-x-3">
                    <button 
                        v-if="selectedProducts.length"
                        class="px-4 py-2 rounded bg-white flex items-center justify-center space-x-1 border text-red-500"
                        @click.prevent="openDeleteProductsModal">
                        <div class="flex items-center justify-center space-x-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current"><path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"/></svg>
                            <span>{{ $t('actions.delete', $store.state.locale) }}: {{ selectedProducts.length}}</span>
                        </div>
                    </button>
                   <!--  <button 
                        class="px-4 py-2 rounded bg-white flex items-center justify-center space-x-1 border"
                        @click.prevent="checkProducts">
                        <div v-if="status === 'void' || status === 'fetched'" class="flex items-center justify-center space-x-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.1,0A1.1,1.1,0,0,0,13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-4.71-4.71-.29.3V12a1,1,0,0,0-2,0v2.59l-.29-.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l2-2a1,1,0,0,0-1.42-1.42Z"/></svg>
                            <span>Check</span>
                        </div>
                        <svg v-else class="animate-spin w-5 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                    </button> -->
                    <button 
                        class="px-4 py-2 rounded bg-white flex items-center justify-center space-x-1 border"
                        @click.prevent="createCSV"
                        :disabled="csvStatus !== 'void'">
                        <div v-if="csvStatus === 'void'" class="flex items-center justify-center space-x-1">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 min-w-5 fill-current"><path d="M20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.1,0A1.1,1.1,0,0,0,13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-4.71-4.71-.29.3V12a1,1,0,0,0-2,0v2.59l-.29-.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l2-2a1,1,0,0,0-1.42-1.42Z"/></svg>
                            <span>Excel</span>
                        </div>
                        <svg v-else class="animate-spin w-5 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                    </button>
                </div>
            </div>
            <table class="table block space-y-10 w-full min-w-full">
                <thead>
                    <tr v-if="status !== 'fetched'" class="bg-white dark:bg-gray-800 shadow border-b-2 dark:border-gray-700">
                        <td class="py-2 px-4">
                            <button 
                                class="w-5 h-5 rounded border text-white flex items-center justify-center bg-gray-100 dark:bg-gray-700 dark:border-gray-500">
                            </button>
                        </td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.productId', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.name', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.quantity', $store.state.locale) }} (Kg)</td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.reference_date', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.formulator', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.status', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.created_at', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-center">{{ $t('productTable.cols.actions', $store.state.locale) }}</td>
                    </tr>
                    <tr v-else class="bg-white dark:bg-gray-800 shadow border-b-2 dark:border-gray-700">
                        <td class="py-2 px-4">
                            <button 
                                class="w-5 h-5 rounded border text-white flex items-center justify-center"
                                :class="products.data.length && selectedProducts.length === products.data.length ? 'bg-light-green-500 border-light-green-500' : 'bg-gray-100 dark:bg-gray-700 dark:border-gray-500'"
                                @click.prevent="toggleProducts">
                                <svg v-show="products.data.length && selectedProducts.length === products.data.length" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>    
                            </button>
                        </td>
                        <td class="py-2 px-4 text-sm">
                            <button 
                                    class="flex items-center space-x-1 p-1 hover:bg-gray-200 dark:hover:bg-gray-900 duration-300 rounded" 
                                    title="Riordina per nome"
                                    @click.prevent="reorderBy('id')">
                                    <span>{{ $t('productTable.cols.productId', $store.state.locale) }}</span>
                                    <div 
                                        class="flex flex-col items-center justify-center p-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-blue-500" :class="orderBy === 'id' && orderType === 'desc' ? 'text-blue-500' : 'text-gray-400'"><path d="M17,13.41,12.71,9.17a1,1,0,0,0-1.42,0L7.05,13.41a1,1,0,0,0,0,1.42,1,1,0,0,0,1.41,0L12,11.29l3.54,3.54a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29A1,1,0,0,0,17,13.41Z"/></svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-blue-500" :class="orderBy === 'id' && orderType === 'asc' ? 'text-blue-500' : 'text-gray-400'"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                    </div>
                                </button>
                        </td>
                        <td class="py-2 px-4 text-sm">
                            <button 
                                class="flex items-center space-x-1 p-1 hover:bg-gray-200 dark:hover:bg-gray-900 duration-300 rounded" 
                                title="Riordina per nome"
                                @click.prevent="reorderBy('name')">
                                <span>{{ $t('productTable.cols.name', $store.state.locale) }}</span>
                                <div 
                                    class="flex flex-col items-center justify-center p-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-blue-500" :class="orderBy === 'name' && orderType === 'desc' ? 'text-blue-500' : 'text-gray-400'"><path d="M17,13.41,12.71,9.17a1,1,0,0,0-1.42,0L7.05,13.41a1,1,0,0,0,0,1.42,1,1,0,0,0,1.41,0L12,11.29l3.54,3.54a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29A1,1,0,0,0,17,13.41Z"/></svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current text-blue-500" :class="orderBy === 'name' && orderType === 'asc' ? 'text-blue-500' : 'text-gray-400'"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                </div>
                            </button>
                        </td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.quantity', $store.state.locale) }} (Kg)</td>
                        <td class="py-2 px-4 max-w-xs">
                            <div class="flex items-center space-x-2">
                                <span>{{ $t('productTable.cols.reference_date', $store.state.locale) }}</span>
                            </div>
                        </td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.formulator', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-sm">
                            <button 
                                class="flex flex-col items-center space-y-1 p-1 hover:bg-gray-200 dark:hover:bg-gray-900 duration-300 rounded" 
                                title="Riordina per nome"
                                @click.prevent="openStatusModal">
                                <div class="flex items-center space-x-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 min-w-4 fill-current"><path d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/></svg>
                                    <span>{{ $t('productTable.cols.status', $store.state.locale) }}</span>
                                </div>
                                <span 
                                    class="py-1 px-2 text-xs rounded-full flex items-center justify-center whitespace-nowrap"
                                    :class="setSelectedStatusClasses">
                                    {{ setSelectedStatusLabel }}
                                </span>
                            </button>
                        </td>
                        <td class="py-2 px-4 text-sm">{{ $t('productTable.cols.created_at', $store.state.locale) }}</td>
                        <td class="py-2 px-4 text-center">{{ $t('productTable.cols.actions', $store.state.locale) }}</td>
                    </tr>
                </thead>
                <tbody v-if="status === 'loading'">
                    <tr>
                        <td colspan="9" class="w-full p-3"> 
                            <div class="flex flex-col space-y-2 items-center justify-center text-center w-full">
                                <svg class="animate-spin w-12 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="status === 'error'">
                    <tr>
                        <td colspan="9" 
                            class="w-full p-3"> 
                            <div class="w-full flex flex-col space-y-3 items-center justify-center text-center">
                                <h3 class="text-2xl">
                                    {{ $t('productTable.no_product', $store.state.locale) }}
                                </h3>
                                <button 
                                    @click.prevent="init"
                                    class="bg-white dark:bg-gray-800 rounded border dark:border-gray-700 px-4 py-2 hover:border-blue-500 dark:hover:border-blue-400 hover:text-blue-500 dark:hover:text-blue-400 duration-300 flex items-center justify-center space-x-1"
                                    :title="$t('dashnav.button.import', $store.state.locale) + ' file'">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z"/></svg>
                                    <span class="text-sm">{{ $t('actions.tryAgain', $store.state.locale) }}</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="status !== 'loading'">
                    <template v-if="products.data.length">
                        <TableRow 
                            v-for="product in products.data"
                            :key="product.id"
                            :parent_product="product"
                            :isSelected="selectedProducts.some(p => p.id === product.id)"
                            :selectedDate="selected_date"
                            @productDeleted="deleteProductFromArray"
                            @selectProduct="selectProduct"
                        />
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="9" class="text-center  w-full p-3"> 
                                <h3 class="text-2xl">
                                    {{ $t('productTable.no_product', $store.state.locale) }}
                                </h3>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <TablePagination
            v-if="status !== 'loading'"
            :current_page="products.current_page"
            :last_page="products.last_page"
            :links="products.links"
            :perPage="perPage === 'all' ? products.total : perPage"
            :total="products.total"
            @fetch="fetch"
        />
        <teleport to="body">
            <!-- Select Date Modal -->
            <div v-if="selection_date_modal" class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
                <div class="flex flex-col w-full h-full items-center justify-center">
                    <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-6 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3">
                        <div class="flex items-start justify-between">
                            <button 
                                title="Chiudi"
                                @click.prevent="() => {selection_date_modal = false;}"
                                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                                    </svg>
                            </button>
                        </div>
                        <h1 class="text-xl flex items-center space-x-1">
                            <span>{{ $t('selectDateModal.title', $store.state.locale) }}</span> 
                        </h1>
                        <!-- Date Selection -->
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <!-- Month -->
                            <div class="mb-1 sm:mb-2">
                                <label for="from" class="inline-block mb-1 uppercase">{{ $t('selectDateModal.month', $store.state.locale) }}</label>
                                <div class="w-full relative">
                                    <select v-model="selected_month" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400">
                                        <option value="0" selected disabled>{{ $t('selectDateModal.month_select', $store.state.locale) }}</option>
                                        <option
                                            v-for="(m, i) in months"  :key="i"
                                            :value="i+1"  
                                        >
                                            {{ $t(`months.${m}`, $store.state.locale) }}
                                        </option>
                                    </select>
                                    <span class="absolute right-4 top-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                    </span>
                                </div>
                                <span
                                    v-if="$store.state.errors.errors.from" 
                                    class="font-bold text-sm text-red-500" >
                                    {{ $store.state.errors.errors.from[0] }}
                                </span>
                            </div>
                            <!-- Year -->
                            <div class="mb-1 sm:mb-2">
                                <label for="from" class="inline-block mb-1 uppercase">{{ $t('selectDateModal.year', $store.state.locale) }}</label>
                                <div class="w-full relative">
                                    <select v-model="selected_year" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-blue-500 focus:shadow-outline dark:bg-gray-800 dark:border-gray-700 dark:hover:border-blue-400 dark:focus:border-blue-400">
                                        <option value="0" selected disabled>{{ $t('selectDateModal.year_select', $store.state.locale) }}</option>
                                        <option
                                            v-for="(y, i) in years"  :key="i"
                                            :value="y"  
                                        >
                                            {{ y }}
                                        </option>
                                    </select>
                                    <span class="absolute right-4 top-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 fill-current"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/></svg>
                                    </span>
                                </div>
                                <span
                                    v-if="$store.state.errors.errors.from" 
                                    class="font-bold text-sm text-red-500" >
                                    {{ $store.state.errors.errors.from[0] }}
                                </span>
                            </div>
                        </div>
                        <div class="flex w-full items-center justify-end">  
                            <button 
                            :title="$t('actions.select', $store.state.locale)"
                                @click.prevent="setSelectedDate"
                                class="px-5 py-3 font-medium leading-5 text-center text-white bg-blue-500 rounded lg:mt-0 hover:bg-blue-300 duration-300 lg:w-auto">
                                {{ $t('actions.select', $store.state.locale) }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Status Modal -->
            <div v-if="showStatusModal" class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
                <div class="flex flex-col w-full h-full items-center justify-center">
                    <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-6 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3">
                        <div class="flex items-start justify-between">
                            <button 
                                title="Chiudi"
                                @click.prevent="closeStatusModal"
                                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                                    </svg>
                            </button>
                        </div>
                        <h1 class="text-xl flex items-center space-x-1">
                            <span>{{ $t('statusModal.title', $store.state.locale) }}</span> 
                        </h1>
                        <!-- Date Selection -->
                        <div class="flex flex-col space-y-3">
                            <button
                                v-for="s in availableStatus" :key="s"
                                class="w-40 max-w-40 flex items-center justify-start space-x-2 hover:bg-gray-100 px-1 rounded duration-300 text-left"
                                @click.prevent="setSelectedStatus(s)">
                                <div 
                                    class="w-5 h-5 min-w-5 border rounded flex items-center justify-center"
                                    :class="{'bg-blue-500' : s === newSelectedStatus}">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 min-w-4 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>
                                </div>
                                <span>{{ $t(`statusModal.${s}`, $store.state.locale) }}</span>
                            </button>
                        </div>
                        <div class="flex w-full items-center justify-end">  
                            <button 
                                :title="$t('statusModal.apply', $store.state.locale)"
                                @click.prevent="filterByStatus"
                                class="px-5 py-3 font-medium leading-5 text-center text-white bg-blue-500 rounded lg:mt-0 hover:bg-blue-300 duration-300 lg:w-auto">
                                {{ $t('statusModal.apply', $store.state.locale) }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Delete Products Modal -->
            <DeleteProductsModal 
                v-if="showDeleteProductsModal && selectedProducts.length"
                :products="selectedProducts"
                @closeModal="closeDeleteProductsModal"
                @productsDeleted="init"
            />
        </teleport>
    </div>
</template>
<script>
import { json2csv } from 'json-2-csv';
import moment from 'moment';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { ref, reactive, computed, defineAsyncComponent } from 'vue';
import TableRow from '../../components/product/TableRow';
import TablePagination from '../../components/tables/TablePagination.vue';
import { useAbortController } from '../../composables/useAbortController';
import ProductService from '../../services/ProductService';
import {bitls_email} from '../../config/BitlsEmails';
import useMatchAlgorithm from '../../composables/useMatchAlgorithm';
//import ZdhcServiceV2 from '../../services/Zdhc/ZdhcServiceV2';
import ZdhcServiceV5 from '../../services/Zdhc/ZdhcServiceV5';
//import { useRoute, useRouter } from 'vue-router';
//import ZdhcProductService from '../../services/ZdhcProductService';


export default 
{
    components: 
    { 
        TableRow, TablePagination,
        DeleteProductsModal: defineAsyncComponent( () => import('../../components/modals/DeleteProductsModal.vue'))
    },
    setup()
    {
        /**
         *
         * Data
         *
         */
        // const route = useRoute();
        // const router = useRouter();
        //const page_status = ref('status_all');
        //const { showZdhcProduct } = ZdhcProductService();
        const store = useStore();
        const totalGuids = ref(0);
        const orderBy = ref('name');
        const search_input = ref('');
        const orderType = ref('asc');
        const status = ref('loading');
        const csvStatus = ref('void');
        const {t, locale} = useI18n();
        const selectedProducts = ref([]);
        const perPage = ref(store.state.per_page ?? 15);
        const selectedStatus = ref('all');
        const showStatusModal = ref(false);
        const newSelectedStatus = ref('all');
        let selection_date_modal = ref(false);
        const showPerPageDropdown = ref(false);
        const showDeleteProductsModal = ref(false);
        //const { getProductByGuid } = ZdhcServiceV2();
        const { searchZdhcProductByGuid } = ZdhcServiceV5();
        let selected_month= ref(moment().format('M'));
        let selected_year= ref(moment().format('YYYY'));
        const selected_date = ref(moment().format('YYYY-MM-DD'));
        const { checkForExistingZdhcProduct, counter } = useMatchAlgorithm();
        const {indexProduct, fetchProduct, indexProductWithAssociations} = ProductService();
        let years = ref(Array.from({length: moment().format('YYYY') - 1900}, (value, index) => 1901 + index));
        const products = reactive({
            data: [],
            next_page_url: null,
            links: null,
            current_page: null,
            last_page: null,
            total: 0
        });
        const availableStatus = [
            "all", 
            "match", 
            "manual_match",
            "no_match",
            "action_required"
        ];
        let months = ref([
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ]);

        /**
         *
         * Computed
         *
         */
        const setCompletionPercentage = computed( () => 
        {
            if(totalGuids.value === 0) return 0;
            else 
            {
                let p = (counter.value * 100) / totalGuids.value;
                return p.toFixed(0);
            }
        });
        const setReferencePeriod = computed( () => 
        {
            return moment(selected_date.value, 'YYYY-MM-DD').format('MMMM YYYY');
        });
        const setSelectedStatusLabel = computed( () => 
        {
            switch(selectedStatus.value)
            {
                case 'match':
                    return 'Match';
                case 'manual_match':
                    return 'Manual match';
                case 'no_match':
                    return 'No match';
                case 'action_required':
                    return 'Action required'
                case 'all': default:
                    return t(`statusModal.${selectedStatus.value}`, store.state.locale);
            }
        });
        const setSelectedStatusClasses = computed( () => 
        {
            switch(selectedStatus.value)
            {
                case 'match':
                    return 'bg-green-50 text-green-500';
                case 'manual_match':
                    return 'bg-light-blue-50 text-light-blue-500';
                case 'no_match':
                    return 'bg-red-50 text-red-500';
                case 'action_required':
                    return 'bg-orange-50 text-deep-orange-500'
                case 'all': default:
                    return 'bg-blue-500 text-white';
            }
        });

        /**
         *
         * Methods
         *
         */
        const init = () => 
        {
            closeStatusModal();
            closeDeleteProductsModal();
            selectedProducts.value = [];
            selected_date.value = store.state.referencePeriod;
            controller.abort();
            status.value = 'loading';
            //let todayDate = moment().format('YYYY-MM-DD');
            /* If  selected_date has already been checked, just get products, else check it*/
            /* if(todayDate in store.state.checkedDates && store.state.checkedDates[todayDate].includes(selected_date.value)) getProducts();
            else 
            {
                checkProducts();
            } */
            getProducts();
        };
        const checkProducts = async () => 
        {
            status.value = 'loading';
            const indexRes = await indexProductWithAssociations(selected_date.value);
            if(indexRes)
            {
                totalGuids.value = indexRes.results.length;
                if(indexRes.results.length)
                {
                    const checkRes = await checkForExistingZdhcProduct(indexRes.results);
                    if(checkRes.status === 'success')
                    {
                        store.commit('PUSH_CHECKED_DATE', selected_date.value); 
                        getProducts();
                    }
                    else status.value = 'error';
                }
                else getProducts();
            }  
        };
        const getProducts = () =>
        {
            indexProduct( 
                selected_date.value,
                orderType.value, 
                orderBy.value, 
                search_input.value,
                selectedStatus.value,
                perPage.value
            )
            .then( res => 
            {
                products.next_page_url = res.results.next_page_url;
                products.data = res.results.data;
                products.links = res.results.links;
                products.last_page_url = res.results.last_page_url;
                products.current_page = res.results.current_page;
                products.last_page = res.results.last_page;
                products.total = res.results.total;
                status.value = 'fetched';
            });
        };
        const reorderBy = (value) => 
        {
            orderType.value = orderBy.value === value 
                ? (orderType.value === 'desc' ? 'asc' : 'desc')
                : 'desc'; 
            orderBy.value = value;
            init();
        };
        const deleteProductFromArray = (id) => 
        {   
            for(let i = 0; i < products.data.length; i++)
            {
                if(products.data[i].id === id) {
                    products.data.splice(i, 1);
                }
            }
        };
        const setSelectedDate = () => 
        {
            selection_date_modal.value = false;
            selected_date.value = moment(`${selected_year.value}-${selected_month.value}-1`).format('YYYY-MM-DD');
            //router.push({name: 'Dashboard', query: {d: selected_date.value}})
            store.commit('SET_REFERENCE_PERIOD', selected_date.value);
            // store.commit('PUSH_CHECKED_DATE', selected_date.value); 
            init();
        };
        const openStatusModal = () => 
        {
            showStatusModal.value = true;
            document.body.classList.add('overflow-hidden');
        };
        const closeStatusModal = () => 
        {
            showStatusModal.value = false;
            document.body.classList.remove('overflow-hidden');
        };
       /*  const setPageStatus = () =>
        {
            switch(page_status.value)
            {
                case 'status_active':
                    page_status.value = 'status_blocked'; break;
                case 'status_blocked':
                    page_status.value = 'status_deleted'; break;
                case 'status_deleted':
                    page_status.value = 'status_all'; break;
                case 'status_all':
                    page_status.value = 'status_active'; break;
                default:
                    page_status.value = 'status_active';
            }
            init();
        } */
        const fetch = (endpoint) =>
        {
            status.value = 'loading';
            fetchProduct(endpoint)
            .then( res => 
            {
                products.next_page_url = res.results.next_page_url;
                products.data = res.results.data;
                products.links = res.results.links;
                products.last_page_url = res.results.last_page_url;
                products.current_page = res.results.current_page;
                products.last_page = res.results.last_page;
                status.value = 'fetched';
            });
        };
        const setPerPage = (value) => 
        {
            perPage.value = value;
            store.commit('SET_PER_PAGE', perPage.value);
            init();
        };
        const closePerPageDropdown = () => 
        {
            showPerPageDropdown.value = false;
        };
        const setSelectedStatus = (value) => 
        {
            if(availableStatus.includes(value)) newSelectedStatus.value = value;
            else newSelectedStatus.value = 'all';
        };
        const filterByStatus = () => 
        {
            if(newSelectedStatus.value !== selectedStatus.value)
            {
                selectedStatus.value = newSelectedStatus.value;
                init();
            }
        };
        const checkGatewayProduct = async (guid = null) => 
        {
            if(guid)
            {
                //const res = await showZdhcProduct(id);
                const res = await searchZdhcProductByGuid(guid);
                if(res.data?.result?.success) return res.data.data[0];
            }
            new Promise((resolve) => 
            {
                resolve();
            });
        }
        const createCSV = async () => 
        {
            if(products.data.length)
            {
                csvStatus.value = 'loading';
                var options = {
                    delimiter: {
                        field: ';', // Comma field delimiter
                        array: ',', // Semicolon array value delimiter
                        eol: '\n' // Newline delimiter
                    },
                    prependHeader: true,
                    sortHeader: false,
                    trimHeaderValues: false,
                    trimFieldValues: false,
                    keys: ['Id', 'Name', 'Formulator', 'Status', 'Gateway Product', 'Gateway Level', 'Quantity(Kg)', 'Reference Period']
                };
                if(bitls_email.includes(store.state.auth.user.email))
                {
                    options.keys.push('BITLS');
                    options.keys.push('Gateway Product GUID');
                }
                let results = await Promise.all(
                    products.data.map( async (item) => 
                    {
                        let incheck_reference = item.incheck_references[0];
                        let zdhcProduct = {};
                        let productName = '-';
                        let certifications = [];
                        let bitls = item.bitls_code ? item.bitls_code : ''; 
                        let zdhc_check_product = incheck_reference.zdhc_product_id 
                                                        ? incheck_reference.match
                                                        : (incheck_reference.suggestion_id
                                                                            ? incheck_reference.suggestion.zdhc_product
                                                                            : incheck_reference.no_match_id 
                                                                                                ? 'noMatch'
                                                                                                : null);
                        let status = incheck_reference.zdhc_product_id 
                                                        ? 'Match'
                                                        : (incheck_reference.suggestion_id
                                                                            ? 'Manual match'
                                                                            : incheck_reference.no_match_id 
                                                                                                ? 'noMatch'
                                                                                                : 'Action Required');
    
                        if(zdhc_check_product?.guid)
                        {
                            zdhcProduct = await checkGatewayProduct(zdhc_check_product.guid);
                        }
                        if(zdhcProduct)
                        {
                            productName = zdhcProduct.productName ? zdhcProduct.productName : '-';
                            
                            if(zdhcProduct.ProductCertifications)
                            {
                                zdhcProduct.ProductCertifications.forEach(c => 
                                {
                                    let certification = '';
                                    if(c.certification) certification += `${c.certification}`;
                                    if(c.certification && c.certificationResult) certification +=': ';
                                    if(c.certificationResult) certification += `${c.certificationResult}`;
                                    certifications.push(certification);
                                });
                            }
                        }

                        let result = 
                        {
                            Id: item.id,
                            Name: item.name,
                            Formulator: item.formulator ? item.formulator.name : item.formulator_name,
                            Status : status,
                            'Gateway Product': productName ?? '-',
                            'Gateway Level': certifications.length ? certifications.join(', ') : '-',
                            'Quantity(Kg)': incheck_reference.quantity === 0 ? incheck_reference.quantity : (incheck_reference.quantity / 100),
                            'Reference Period': moment(incheck_reference.reference_date).format('YYYY-MM-DD')
                        }
                        if(bitls_email.includes(store.state.auth.user.email))
                        {
                            result['BITLS'] = bitls;
                            result['Gateway Product GUID'] = zdhcProduct.productGUID;
                        }
                        return result;
                    })
                );
                csvStatus.value = 'void';
                return json2csv(results, (err, csv) => createDownloadLink(Date.now() + '_products.csv', csv), options)
            }
        }
        const createDownloadLink = (filename, csv, head) => 
        {
            if(!head) head = 'data:text/csv'
            var link = document.createElement('a');
            link.setAttribute('href', head+';charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        const selectProduct = (product) => 
        {
            if(selectedProducts.value.some( p => p.id === product.id)) selectedProducts.value = selectedProducts.value.filter(p => p.id !== product.id);
            else 
            {
                selectedProducts.value.push({id:product.id, incheck_reference: product.incheck_references[0].id});
            }
        };
        const toggleProducts = () => 
        {
            if(selectedProducts.value.length === products.data.length) selectedProducts.value = [];
            else 
            {
                let selectableProducts = products.data.filter( p => 
                {
                    let incheck_date = moment(p.incheck_references[0].reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                    if(!p.inventories.length  
                        || !p.inventories.some( i => 
                        {
                            let inventory_date = moment(i.reference_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                            return moment(inventory_date).isSame(moment(incheck_date));
                        })
                    )
                    {
                        return p;
                    }
                });

                selectedProducts.value = selectableProducts.map( p => {return {id: p.id, incheck_reference: p.incheck_references[0].id}});
            }
        };
        const openDeleteProductsModal = () => 
        {
            showDeleteProductsModal.value = true;
            document.body.classList.add('overflow-hidden');
        };
        const closeDeleteProductsModal = () => 
        {
            showDeleteProductsModal.value = false;
            document.body.classList.remove('overflow-hidden');
        };

        /**
         *
         * Hooks
         *
         */
        moment.locale(store.state.locale);
        locale.value = store.state.locale;
        //if(route.query.d) selected_date.value = route.query.d
        selected_date.value = store.state.referencePeriod;
        //
        const controller = useAbortController();
        //
        init();

        return {
            /* Data */
            status,
            products,
            orderBy,
            search_input,
            selected_month,
            selected_year,
            months,
            years,
            orderType,
            selection_date_modal,
            perPage,
            showPerPageDropdown,
            showStatusModal,
            availableStatus,
            selectedStatus,
            newSelectedStatus,
            csvStatus,
            selectedProducts,
            counter,
            selected_date,
            /* Computed */
            setReferencePeriod,
            setSelectedStatusLabel,
            setSelectedStatusClasses,
            showDeleteProductsModal,
            setCompletionPercentage,
            /* Methods */
            reorderBy,
            fetch,
            init,
            checkProducts,
            deleteProductFromArray,
            setSelectedDate,
            setPerPage,
            closePerPageDropdown,
            openStatusModal,
            closeStatusModal,
            setSelectedStatus,
            filterByStatus,
            createCSV,
            selectProduct,
            toggleProducts,
            openDeleteProductsModal,
            closeDeleteProductsModal
        }
    }
}
</script>